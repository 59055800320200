<template>
  <Layout class="inner">
    <!--    批量上传-->
    <el-dialog

      :visible.sync="batchUploadVisible"
      width="500px"
      @closed="loadedList = []"
    >
      <div
        v-if="!isLoading && !isCode404"
        class="batchUpload"
      >
        <div class="icon-wrapper">
          <i class="el-icon-upload upload-icon" />
        </div>
        <template v-if="!loadedList.length">
          <div class="batchUpload-tips">
            <uploadExcel
              text="上传表格，仅支持模板格式的excel"
              @uploaded="handleUploadExcel"
            />
          </div>
          <div>
            <el-button
              type="text"
              @click="downloadExcel"
            >
              下载模板
            </el-button>
          </div>
        </template>
        <template
          v-else
        >
          <p>
            读取到
            <span style="color: blue;">{{ loadedList.length }}</span>
            条数据，是否确认上传
          </p>
          <div class="batchUpload-ctrl-area">
            <!-- <uploadExcel
              text="重新上传"
              @uploaded="handleUploadExcel"
            /> -->
            <el-button
              type="primary"
              @click="handlePostExcelData"
            >
              确定
            </el-button>
          </div>
        </template>
      </div>
      <div
        v-if="isLoading"
        v-loading="isLoading"
        class="batchUpload"
      >
        <div class="icon-wrapper">
          <i class="el-icon-upload upload-icon" />
        </div>
        <div>导入中</div>
      </div>
      <div
        v-if="isCode404 && !errTable"
        class="batchUpload"
      >
        <div class="icon-wrapper">
          <i class="el-icon-upload upload-icon" />
        </div>
        <div>上传数据校验失败，请重新检查数据。</div>
        <button @click="backUpdata">
          返回上一步
        </button>
      </div>
      <div
        v-if="errTable"
        class="batchUpload"
      >
        <div class="icon-wrapper">
          <i class="el-icon-upload upload-icon" />
        </div>
        <div>
          共上传{{ loadedList.length }}条数据，其中{{ errArr.length || 0 }}条错误。请返回上一步，修正后重新上传表格。
        </div>
        <!-- <div
          v-for="(item,index) of errArr"
          :key="index"
        >
          {{ item }}
        </div> -->
        <el-table
          v-loading="isLoading"
          :data="errArr"
        >
          <el-table-column
            prop="sort"
            label="排序"
          />
          <el-table-column
            prop="gameId"
            label="游戏ID"
          />
          <el-table-column
            prop="status"
            label="是否上架"
          >
            <template v-slot="{row}">
              {{ row.status === 1 ? '上架': '下架' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="errMsg"
            label="错误信息"
          />
        </el-table>
        <button @click="backUpdata">
          返回上一步
        </button>
      </div>
    </el-dialog>
    <!--    批量上传-->
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="search">
          <el-input
            v-model="formData.searchWord"
            clearable
            placeholder="名称/ID"
          />
        </el-form-item>
        <el-form-item prop="state">
          <el-select
            v-model="formData.status"
            placeholder="上架状态"
            clearable
          >
            <el-option
              v-for="(item, index) in appModuleStatesList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="openBatchUpload"
        >
          批量导入
        </el-button>
        <el-button
          type="primary"
          @click="handleCreateAD"
        >
          新建
        </el-button>
      </template>
    </BHeader>
    <div class="data-main">
      <TypeManagement
        v-show="isOpen"
        class="types"
        :class="{isOpen: isOpen}"
        @update="handleTypeUpdated"
      />
      <div
        class="table"
        :class="{isOpen: isOpen}"
      >
        <el-table
          v-loading="loading"
          :data="listInfo.list"
          stripe
          border
          height="calc(100% - 96px)"
          style="width: 100%;"
        >
          <el-table-column
            prop="sort"
            label="排序"
            min-width="100"
          />
          <el-table-column
            prop="id"
            label="ID"
            min-width="100"
          />
          <el-table-column
            label="名称"
            min-width="100"
          >
            <template v-slot="{row}">
              {{ row.langDetail.en.title }}
              <span
                v-if="row.gameStatus === 2"
                style="color: red; margin-left: 10px;"
              >已下架</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="image"
            min-width="120"
            label="图"
          >
            <template v-slot="{row}">
              <div
                class="game-cover"
                :style="{
                  backgroundImage: `url(${(row.langDetail || {}).en.image})`
                }"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="id"
            label="Tag"
            min-width="100"
          >
            <template v-slot="{row}">
              {{ (advertisementTagMap[row.tag] || {}).name || 'UNKNOWN' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            min-width="100px"
            label="上架状态"
          >
            <template v-slot="{row}">
              {{ row.status === 1 ? '上架': '下架' }}
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            width="180"
            label="操作"
          >
            <template v-slot="{row}">
              <BTextButton
                title="编辑信息"
                icon="fa fa-pencil"
                @click="handleEditAD(row)"
              />
              <BTextButton
                :loading="row.loading"
                :title="row.status === 1 ? '启用' : '禁用'"
                :icon="`fa ${row.status === 1 ? 'fa-check-circle' : 'fa-ban' }`"
                @click="handleToggleAD(row)"
              />
              <BTextButton
                title="删除"
                icon="fa fa-trash-o"
                @click="handleDeleteAD(row)"
              />
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="listInfo.total"
          :page.sync="formData.pageNum"
          :limit.sync="formData.pageSize"
          @pagination="queryList"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import TypeManagement from '@/views/app/category/components/TypeManagement'
import { advertisementTagMap, appModuleStatesList } from '@/utils/selectOptions'
import { deleteAD, queryAdList, toggleAD, downloadInviteCode, resourceGameExport } from '@/api/APP_Category'
import { mapState } from 'vuex'
import XLSX from 'xlsx'
import uploadExcel from '@/components/UploadExcel/uploadExcel'
// import { keys, values } from '../../../../mock/user'
// import { pseudoRandom } from '@/utils'
// const idNext = pseudoRandom(7)
export default {
  name: 'ADList',
  components: { TypeManagement, uploadExcel },
  data () {
    return {
      errTable: false,
      errArr: null,
      tempCoverUrl: '',
      currentRows: {},
      isLoading: false,
      isCode404: false,
      loadedList: [],
      batchUploadTemplate: {
        list: []
        // header: ['CDKEY(必填)'],
        // fields: ['cdkeys']
      },
      exportFile: '',
      batchUploadVisible: false,
      appModuleStatesList,
      advertisementTagMap,
      formData: {
        moduleId: '',
        pageNum: 1,
        pageSize: 20
      },
      currentType: {
        id: '',
        titleDetail: { en: '' },
        title: ''
      },
      isOpen: true,
      id: '',
      listInfo: {
        list: [],
        total: 0
      },
      loading: false,
      style: '',
      zoneId: ''
    }
  },
  computed: {
    ...mapState('app', ['appId', 'channel']),
    parentInfo () {
      let pId
      let name
      const currentType = this.currentType
      if (this.isOpen) {
        pId = currentType.id
        name = currentType.titleDetail.en
      } else {
        pId = this.$route.query.id
        name = this.$route.query.name
      }
      return {
        pId: pId,
        name: name
      }
    }
  },
  activated () {
    const { id, style, zoneId } = this.$route.query
    this.zoneId = Number(zoneId)
    this.isOpen = Number(style) === 4
    this.formData.moduleId = id
    this.style = style
    this.queryList()
    if (!this.isOpen) {
      this.queryList()
    }
  },
  created () {
    const { id, style, zoneId } = this.$route.query
    this.zoneId = Number(zoneId)
    this.isOpen = Number(style) === 4
    this.formData.moduleId = id
    this.style = style
    this.queryList()
    if (!this.isOpen) {
      this.queryList()
    }
  },
  methods: {
    backUpdata () {
      this.errTable = false
      this.errArr = null
      this.isLoading = false
      this.isCode404 = false
      this.loadedList = []
    },
    openBatchUpload () {
      this.errTable = false
      this.errArr = null
      this.isLoading = false
      this.isCode404 = false
      this.loadedList = []
      this.batchUploadVisible = true
      // this.currentRows = row
    },
    handlePostExcelData () {
      const { pId } = this.parentInfo
      this.isLoading = true
      resourceGameExport({ pId, tag: 2, platform: 3, dataList: this.exportFile }).then(res => {
        this.isLoading = false
        if (res.code === 200) {
          if (res.data.errList !== null) {
            this.errTable = true
            this.errArr = res.data.errList
          } else {
            this.batchUploadVisible = false
            this.$message.success(
            `成功导入:${res.data.total || '/'} 条数据`
            )
            this.queryList()
          }
        }
      }).finally(() => {
        this.isCode404 = true
        this.isLoading = false
      })
    },

    downloadExcel () {
      const { pId } = this.parentInfo
      downloadInviteCode({
        ...this.formData,
        pId,
        appId: this.appId,
        channel: this.channe,
        tag: 2
      }).then(res => {
        if (res.code === 200) {
          this.downloadFile(res.data.url)
        }
      })
    },
    downloadFile (url) {
      var a = document.createElement('a')
      a.href = url
      a.click()
      window.URL.revokeObjectURL(url)
    },
    uploadExcel (data) {
      return data.list.map(item => {
        return item
      })
    },
    readerData (rawFile) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: 'array' })
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet)
          this.generateData({ header, results })
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },
    generateData ({ header, results: list }) {
      const result = list || []
      this.loadedList = result.map(item => {
        return item
      })
      console.log('generateData', this.loadedList)
    },
    getHeaderRow (sheet) {
      const headers = []
      const range = XLSX.utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
        headers.push(hdr)
      }
      return headers
    },
    handleUploadExcel (data) {
      const result = data.list.map(item => {
        const { 游戏ID, 排序, 状态 } = item
        return [游戏ID, 排序, 状态]
      })
      this.loadedList = result.map(item => {
        console.log(item)
        return item
      })
      this.exportFile = result
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryList()
    },
    queryList () {
      this.loading = true
      const { pId } = this.parentInfo

      queryAdList({
        pId,
        ...this.formData,
        zoneId: this.zoneId,
        appId: this.appId,
        channel: this.channel
      })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list
            this.listInfo.total = res.data.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleTypeUpdated (obj) {
      this.currentType = obj
      this.formData.type = obj.id
      this.handleSearch()
    },
    handleCreateAD () {
      const { pId, name } = this.parentInfo
      const style = this.style
      this.$router.push({
        name: 'ADCreate',
        query: { pId, name, style }
      })
    },
    handleEditAD (row) {
      const { pId, name } = this.parentInfo
      const style = this.style
      this.$router.push({
        name: 'ADEdit',
        query: {
          id: row.id,
          pId,
          name,
          style
        }
      })
    },
    handleDeleteAD (row) {
      this.$confirm('此操作将删除, 是否继续', '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'deleteLoading', true)
          deleteAD({
            id: row.id
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.queryList()
            }
          }).finally(() => {
            this.$set(row, 'deleteLoading', false)
          })
        })
    },
    handleToggleAD (row) {
      const TIPS = row.status !== 1 ? '此操作将启用， 是否继续?' : '此操作将禁用， 是否继续?'
      const NEXT_STATUS = row.status !== 1 ? 1 : 2
      this.$confirm(TIPS, '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'loading', true)
          toggleAD({
            id: row.id,
            status: NEXT_STATUS
          }).then(res => {
            if (res.code === 200) {
              this.$set(row, 'status', NEXT_STATUS)
            }
          }).finally(() => {
            this.$set(row, 'loading', false)
          })
        })
    }
  }
}
</script>

<style lang="less">
    .batchUpload{
      text-align: center;
      .icon-wrapper{
        .upload-icon{
          font-size: 100px;
          color: @primary;
        }
      }
      .batchUpload-tips{
        margin: 20px 0 10px;
      }
      .batchUpload-ctrl-area{
        margin-top: 30px;
        text-align: right;
      }
    }
  .Layout{
    &.inner{
      min-width: 780px;
      .layout-content{
        height: calc(100vh - 130px);
        .el-table__body-wrapper{
          /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: transparent;
          }
          /*定义滚动条轨道 内阴影+圆角*/
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          /*定义滑块 内阴影+圆角*/
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(144,147,153,.3);
            transition: background-color .3s;
            &:hover{
              background-color: rgba(144,147,153,.5);
            }
          }
        }
      }
    }
  }
</style>
<style scoped lang="less">
  .data-main{
    display: flex;
    height: calc(~'100% - 80px');
    >.types {
      &.isOpen{
        width: 150px;
        flex-shrink: 0;
      }
    }
    >.table{
      flex: 1;
      width: 100%;
      &.isOpen{
        width: calc(~'100% - 150px');
      }
    }
  }
  .game-cover{
    width: 96px;
    height: 50px;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
  }
</style>
